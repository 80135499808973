import * as React from 'react';
import { StyledLeafProps } from '../../components/StyledComponent/StyledComponent.types';
import styled from 'styled-components';
import { colorsV2 } from '../../../figma/colors-v2';
import { MARK_ABBR_TITLE } from './defaults';

const Main = styled.abbr`
  background: ${colorsV2.black20};
  user-select: none;
`;
export const AbbrLeafElementReadOnly = ({ attributes, children, className, as = 'span', leaf }: StyledLeafProps) => {
  const ref = React.useRef();

  const title = leaf?.[MARK_ABBR_TITLE];
  return (
    <Main
      ref={ref}
      title={title}
      {...attributes}
      className={className}
    >
      {children}
    </Main>
  );
};
