import * as React from 'react';

import { StyledLeafProps } from './StyledComponent.types';

/**
 * StyledLeaf with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const StyledLeafBase = ({
  attributes,
  children,
  className,
  as = 'span',
}: StyledLeafProps) => {
  const StyledLeafBaseMain = as;

  return (
    <StyledLeafBaseMain {...attributes} className={className}>
      {children}
    </StyledLeafBaseMain>
  );
};

export const StyledLeaf = StyledLeafBase;
