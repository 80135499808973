import { throttle } from 'lodash';
import * as React from 'react';

const SELECTION_THROTTLE_TIMEOUT = 101; // slate use throttle 100ms

export const useDolEditorEventStream = (dolEditorStore: any) => {
  React.useEffect(() => {
    const onSelectionChange = throttle(() => {
      dolEditorStore.selectionChange();
    }, SELECTION_THROTTLE_TIMEOUT);

    document.addEventListener('selectionchange', onSelectionChange, true);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange, true);
    };
  }, [dolEditorStore]);
};
