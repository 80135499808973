import { GetOnHotkeyToggleMarkOptions } from '../../common/utils/getOnHotkeyToggleMark';
import { StyledLeaf } from '../../components/StyledComponent/StyledLeaf';
import { CiteKeyOption, CitePluginOptionsValues } from './types';

export const MARK_CITE = 'cite';

export const DEFAULTS_CITE: Record<
  CiteKeyOption,
  CitePluginOptionsValues & GetOnHotkeyToggleMarkOptions
> = {
  cite: {
    component: StyledLeaf,
    type: MARK_CITE,
    rootProps: {
      as: 'cite',
    },
  },
};
