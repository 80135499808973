import { GetOnHotkeyToggleMarkOptions } from '../../common/utils/getOnHotkeyToggleMark';
import { StyledLeaf } from '../../components/StyledComponent/StyledLeaf';
import { QKeyOption, QPluginOptionsValues } from './types';

export const MARK_Q = 'q';

export const DEFAULTS_Q: Record<QKeyOption, QPluginOptionsValues & GetOnHotkeyToggleMarkOptions> = {
  q: {
    component: StyledLeaf,
    type: MARK_Q,
    rootProps: {
      as: 'q',
    },
  },
};
