import { trim } from 'lodash';

const PIXEL_REGEX = /\dpx/;

/**
 * convert pixel string to number
 * return 0 if not a pixel
 */
export const pxToNumber = (px: string | number) => {
  if (typeof px === 'number') return px;

  if (!PIXEL_REGEX.test(px)) return 0;

  return +px.replace('px', '');
};

export const getRangeSelectionFromSavedSelection = ({
  containerEl,
  savedSelection,
  frameWindow = window,
  frameDocument = document,
}) => {
  if (frameWindow.getSelection && frameDocument.createRange) {
    let charIndex = 0;
    const range = frameDocument.createRange();
    range.setStart(containerEl, 0);
    range.collapse(true);

    const nodeStack = [containerEl];
    let node;
    let foundStart = false;
    let stop = false;

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType === Node.TEXT_NODE) {
        const nextCharIndex = charIndex + node.length;
        if (
          !foundStart &&
          savedSelection.start >= charIndex &&
          savedSelection.start <= nextCharIndex
        ) {
          range.setStart(node, savedSelection.start - charIndex);
          foundStart = true;
        }
        if (foundStart && savedSelection.end >= charIndex && savedSelection.end <= nextCharIndex) {
          range.setEnd(node, savedSelection.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i > 0) {
          i -= 1;
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    return range;
  }
  return null;
};

export const HIGHLIGHT_RANGE_CLASS_NAME = 'highlight-range';

export const getSelectionText = ({ frameWindow = window }) => {
  let text = '';
  if (frameWindow.getSelection) {
    text = frameWindow.getSelection().toString();
  }
  return trim(text, '\n');
};

export const closestDomNode = (node, selector) => {
  try {
    return node.closest(selector);
    // eslint-disable-next-line no-empty
  } catch (ex) {}
  return undefined;
};

export const getHtmlFromClipboard = async (clipboardData) => {
  const allHtmls = await Promise.all(
    clipboardData.map(async (data) => {
      const blob = await data.getType('text/html');
      const html = await new Response(blob).text();
      return html;
    })
  );
  return allHtmls;
};

/**
 * Get the rectangle of a given Range.
 *
 * @param {Range} range The range.
 *
 * @return {DOMRect} The rectangle.
 */
export function getRectangleFromRange(range) {
  // For uncollapsed ranges, get the rectangle that bounds the contents of the
  // range; this a rectangle enclosing the union of the bounding rectangles
  // for all the elements in the range.
  if (!range.collapsed) {
    return range.getBoundingClientRect();
  }

  const { startContainer } = range;
  const { ownerDocument } = startContainer;

  // Correct invalid "BR" ranges. The cannot contain any children.
  if (startContainer.nodeName === 'BR') {
    const { parentNode } = startContainer;
    const index = Array.from(parentNode.childNodes).indexOf(startContainer);

    range = ownerDocument.createRange();
    range.setStart(parentNode, index);
    range.setEnd(parentNode, index);
  }

  let rect = range.getClientRects()[0];

  // If the collapsed range starts (and therefore ends) at an element node,
  // `getClientRects` can be empty in some browsers. This can be resolved
  // by adding a temporary text node with zero-width space to the range.
  //
  // See: https://stackoverflow.com/a/6847328/995445
  if (!rect) {
    const padNode = ownerDocument.createTextNode('\u200b');
    // Do not modify the live range.
    range = range.cloneRange();
    range.insertNode(padNode);
    rect = range.getClientRects()[0];
    padNode.parentNode.removeChild(padNode);
  }

  return rect;
}

export function computeCaretRect(win) {
  const selection = win.getSelection();
  const range = selection.rangeCount ? selection.getRangeAt(0) : null;

  if (!range) {
    return undefined;
  }

  return getRectangleFromRange(range);
}

export const cssText = () => {
  const css = [];

  for (let sheeti = 0; sheeti < document.styleSheets.length; sheeti++) {
    const sheet = document.styleSheets[sheeti];
    if ((sheet.ownerNode as any).outerHTML.includes('data-styled="active')) {
      css.push(`${(sheet.ownerNode as any).innerHTML}\n}\n`);
    }
  }
  return css.join('\n');
};

export const getHTMLContent = () => {
  const el = document.getElementById('email-content');
  const spanEls = el.querySelectorAll('span');
  const images = el.querySelectorAll('img');
  const attachedEls = el.querySelectorAll('.attached-file');
  const lis = el.querySelectorAll('li > div');
  const uls = el.querySelectorAll('ul');
  const divs = el.querySelectorAll('div');
  const devs = el.querySelectorAll('.des');
  if (spanEls) {
    for (let i = 1; i < spanEls.length - 1; i++) {
      if (spanEls[i].innerText.length > 1) {
        const node = spanEls[i].parentNode as HTMLDivElement;
        node.style.marginBottom = '24px';
      }
    }
  }
  if (images) {
    for (let i = 0; i < images.length; i++) {
      images[i].style.margin = '0 auto';
      const node = images[i].parentNode as HTMLDivElement;
      node.style.marginBottom = '24px';
    }
  }
  if (attachedEls) {
    for (let i = 0; i < attachedEls.length; i++) {
      const aEl = attachedEls[i] as HTMLDivElement;
      aEl.style.marginBottom = '24px';
    }
  }
  if (lis) {
    for (let i = 0; i < lis.length; i++) {
      (lis[i] as HTMLDivElement).style.marginBottom = '0';
    }
  }
  if (uls) {
    for (let i = 0; i < uls.length; i++) {
      uls[i].style.marginBottom = '24px';
      uls[i].style.marginTop = '0';
    }
  }
  if (divs) {
    for (let i = 0; i < divs.length; i++) {
      divs[i].style.whiteSpace = 'normal';
    }
  }
  if (devs) {
    for (let i = 0; i < devs.length; i++) {
      (devs[i] as HTMLDivElement).style.marginBottom = '0';
    }
  }
  return `<html><style>html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none; width: 100%}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}</style>
    <style>${cssText()}</style> ${el.innerHTML}</html>`;
};

export const focusOnContentEditable = (el) => {
  setTimeout(() => {
    el.focus();
    if (el.hasChildNodes()) {
      const s = window.getSelection();
      const r = document.createRange();
      const e = el.childElementCount > 0 ? el.lastChild : el;
      r.setStart(e, 1);
      r.setEnd(e, 1);
      s.removeAllRanges();
      s.addRange(r);
    }
  }, 0);
};

export const getNextElementWithClass = (currentElement: HTMLElement, className: string) => {
  let nextElement = currentElement.nextElementSibling;
  while (nextElement && !nextElement.classList.contains(className)) {
    nextElement = nextElement.nextElementSibling;
  }
  return nextElement;
};

export const getPreviousElementWithClass = (currentElement: HTMLElement, className: string) => {
  let previousElement = currentElement.previousElementSibling;
  while (previousElement && !previousElement.classList.contains(className)) {
    previousElement = previousElement.previousElementSibling;
  }
  return previousElement;
};
