import * as React from 'react';
import { StyledLeafProps } from '../../components/StyledComponent/StyledComponent.types';
import styled from 'styled-components';
import { colorsV2 } from '../../../figma/colors-v2';
import { DolPopover } from '../../../figma/Popover/DolPopover';
import { shadows } from '../../../figma/shadows';
import { useClickAway } from 'react-use';
import { closestDomNode } from '@doltech/utils/lib/dom';
import { MARK_ABBR_TITLE } from './defaults';
import { ResponsiveTypography } from '../../../figma/Typography/v3/ResponsiveTypography';
import { isCollapsed } from '../../common/queries/isCollapsed';
import { useDolEditorStore } from '../../../figma/DolEditor/dol-editor.store';
import { observer } from 'mobx-react-lite';
import { InputContentGeneral } from '../../../figma/Input/InputContentGeneral/InputContentGeneral';

const Main = styled.abbr`
  background: ${colorsV2.black20};
  user-select: none;
`;

const FormMain = styled.div`
  background-color: ${colorsV2.white100};
  padding: 12px;
  border-radius: 8px;
  box-shadow: ${shadows.zIndex4};

  display: grid;
  gap: 4px;
`;

const AbbrTitleForm = ({ onChange, initialTitle }) => {
  return (
    <FormMain className="abbr-title-form">
      <ResponsiveTypography.Paragraph variant="semi-bold/16-24" color="black60">
        Abbr Title
      </ResponsiveTypography.Paragraph>
      <InputContentGeneral
        placeholder="Nhập title"
        defaultValue={initialTitle}
        onChange={onChange}
      />
    </FormMain>
  );
};

export const AbbrBase = observer(
  ({ attributes, children, className, as = 'span', leaf }: StyledLeafProps) => {
    const store = useDolEditorStore();
    const { editor } = store || {};
    const ref = React.useRef();
    const [collapsed, setCollapsed] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    useClickAway(ref, (e) => {
      const isValidNode = closestDomNode(e.target, '.abbr-title-form');
      if (isValidNode) return;
      setVisible(false);
    });

    React.useEffect(() => {
      if (editor && editor.selection) {
        setCollapsed(isCollapsed(editor.selection));
      } else {
        setCollapsed(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.afterSelectionId]);

    const onFormValueChange = React.useCallback(
      (newTitle) => {
        editor.addMark(MARK_ABBR_TITLE, newTitle);
      },
      [editor]
    );
    const title = leaf?.[MARK_ABBR_TITLE];
    return (
      <DolPopover
        visible={!collapsed && visible}
        trigger={[]}
        overlayClassName="un-styled no-arrow custom-inner-content"
        content={<AbbrTitleForm initialTitle={title} onChange={onFormValueChange} />}
      >
        <Main
          ref={ref}
          onMouseDown={(e) => {
            setVisible(true);
          }}
          title={title}
          {...attributes}
          className={className}
        >
          {children}
        </Main>
      </DolPopover>
    );
  }
);

export const AbbrLeafElement = AbbrBase;
