import * as React from 'react';
import styled from 'styled-components';

import emojiList from '@doltech/utils/lib/emoji-list';
import { numberToRoman } from '@doltech/utils/lib/text';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: start;
  column-gap: 4px;
`;

interface HeadingElementProps {
  className?: string;
  element?: any;
  attributes?: any;
  htmlAttributes?: any;
  children?: React.ReactNode;
  as?: string;
}

export const HeadingElement = (props: HeadingElementProps) => {
  const {
    element,
    attributes,
    children,
    className,
    htmlAttributes,
    as = 'div',
  } = props;

  const selectedEmojiList = [
    'fire',
    'star2',
    'blush',
    'zap',
    'palm_tree',
    'sparkles',
    'tada',
    'cactus',
    'computer',
    'bulb',
    'book',
    'shamrock',
    'football',
    'books',
    'microscope',
    'balloon',
    'desktop_computer',
    'four_leaf_clover',
    'dart',
  ];

  const iconEmojiList = emojiList
    .filter((eachEmoji) => selectedEmojiList.includes(eachEmoji.aliases?.[0]))
    .sort((a, b) => selectedEmojiList.indexOf(a.aliases?.[0]) - selectedEmojiList.indexOf(b.aliases?.[0]));

  const order = (element?.orderIndex || 0) + 1;
  const htmlId = Number.isFinite(element?.headingIndex) && `section-${element?.headingIndex + 1}`;

  return (
    <Container as={as} id={htmlId} className={className} {...htmlAttributes}>
      {Number.isFinite(element?.orderIndex) && (
        <span onMouseDown={(e) => e.preventDefault()}>
          {element?.type === 'h2' && `${numberToRoman(order)}.`}
          {element?.type === 'h3' && iconEmojiList[(element?.orderIndex || 0) % iconEmojiList.length]?.emoji}
          {element?.type === 'h4' && `${order}.`}
        </span>
      )}
      <span {...attributes}>{children}</span>
    </Container>
  );
};
