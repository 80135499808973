import { HeadingKeyOption, HeadingLevelsOption, HeadingPluginOptionsValues } from './types';
import { HeadingElement } from './HeadingElement';

export const ELEMENT_H1 = 'h1';
export const ELEMENT_H2 = 'h2';
export const ELEMENT_H3 = 'h3';
export const ELEMENT_H4 = 'h4';
export const ELEMENT_H5 = 'h5';
export const ELEMENT_H6 = 'h6';

export const DEFAULTS_HEADING: Record<HeadingKeyOption, HeadingPluginOptionsValues> &
  Required<HeadingLevelsOption> = {
  h1: {
    component: HeadingElement,
    type: ELEMENT_H1,
    rootProps: {
      // className: `slate-${ELEMENT_H1}`,
      as: 'h1',
    },
  },
  h2: {
    component: HeadingElement,
    type: ELEMENT_H2,
    rootProps: {
      // className: `slate-${ELEMENT_H2}`,
      as: 'h2',
    },
  },
  h3: {
    component: HeadingElement,
    type: ELEMENT_H3,
    rootProps: {
      // className: `slate-${ELEMENT_H3}`,
      as: 'h3',
    },
  },
  h4: {
    component: HeadingElement,
    type: ELEMENT_H4,
    rootProps: {
      // className: `slate-${ELEMENT_H4}`,
      as: 'h4',
    },
  },
  h5: {
    component: HeadingElement,
    type: ELEMENT_H5,
    rootProps: {
      className: `slate-${ELEMENT_H5}`,
      as: 'h5',
    },
  },
  h6: {
    component: HeadingElement,
    type: ELEMENT_H6,
    rootProps: {
      // className: `slate-${ELEMENT_H6}`,
      as: 'h6',
    },
  },
  levels: 6,
};
