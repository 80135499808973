import { types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import { nanoid } from 'nanoid';
import * as React from 'react';

import { useDolEditorEventStream } from './useDolEditorEventStream';

export const DolEditorStore = t
  .model('DolEditorStore', {
    focusId: t.optional<any>(t.string, nanoid()),
    selectionId: t.optional<any>(t.string, nanoid()),
    blurId: t.optional<any>(t.string, nanoid()),
    afterSelectionId: t.optional<any>(t.string, nanoid()),
  })
  .volatile(() => ({
    editor: null,
    selectionTimeOut: null,
  }))
  .actions((self) => ({
    afterSelectionChange() {
      self.afterSelectionId = nanoid();
    },
  }))
  .actions((self) => ({
    registerEditor(editor) {
      self.editor = editor;
    },
    focusChange() {
      self.focusId = nanoid();
    },
    selectionChange() {
      self.selectionId = nanoid();
      if (self.selectionTimeOut) {
        clearTimeout(self.selectionTimeOut);
      }
      self.selectionTimeOut = setTimeout(() => {
        self.afterSelectionChange();
      }, 10);
    },
    blurChange() {
      self.blurId = nanoid();
    },
  }));

export const useDolEditorStore = () => {
  return useStore(DolEditorStore);
};

export const withDolEditorStore = (Component: any) => (props: any) => {
  const dolEditorCreateStore = useCreateStore(DolEditorStore, {});
  const DolEditorStoreProvider = useProvider(DolEditorStore);

  useDolEditorEventStream(dolEditorCreateStore);

  return (
    <DolEditorStoreProvider value={dolEditorCreateStore}>
      <Component {...props} />
    </DolEditorStoreProvider>
  );
};

