import { GetOnHotkeyToggleMarkOptions } from '../../common/utils/getOnHotkeyToggleMark';
import { AbbrKeyOption, AbbrPluginOptionsValues } from './types';
import { AbbrLeafElement } from './AbbrLeafElement';
import { AbbrLeafElementReadOnly } from './AbbrLeafElementReadOnly';

export const MARK_ABBR = 'abbr';

export const MARK_ABBR_TITLE = 'abbr_title';

export const DEFAULTS_ABBR: Record<
  AbbrKeyOption,
  AbbrPluginOptionsValues & GetOnHotkeyToggleMarkOptions
> = {
  abbr: {
    component: AbbrLeafElement,
    type: MARK_ABBR,
    rootProps: {
      as: 'abbr',
    },
  },
};


export const DEFAULTS_ABBR_READ_ONLY: Record<
  AbbrKeyOption,
  AbbrPluginOptionsValues & GetOnHotkeyToggleMarkOptions
> = {
  abbr: {
    component: AbbrLeafElementReadOnly,
    type: MARK_ABBR,
    rootProps: {
      as: 'abbr',
    },
  },
};
